<template>
  <v-dialog v-model="dialog" max-width="1000">
    <v-card :loading="loading">
      <v-card-title>
        جزئیات تیکت
        <v-spacer />

        <v-btn outlined color="blue" @click="dialog = false">
          بازگشت
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <small>نام فایل</small>
            <h3 class="black--text">{{ selectedData.title }}</h3>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-if="selectedData.category && selectedData.category.name"
          >
            <small>دسته بندی فایل</small>
            <h3 class="black--text">{{ selectedData.category.name }}</h3>
          </v-col>
        </v-row>
        <h3 class="mt-5">توضیحات</h3>
        <h4 class="mt-4">{{ selectedData.desc }}</h4>
        <v-divider />
        <h3 class="mt-5">پاسخ شما</h3>
        <v-textarea
          background-color="grey lighten-2 mt-4"
          solo
          hide-details="auto"
          flat
          class="rounded-lg"
          v-model="answer"
        />
        <v-row align="center" justify="end" class="mt-8 mb-3">
          <v-btn
            :loading="loading"
            class="ma-2"
            dark
            small
            color="success"
            @click="$emit('onSubmitTicket', answer, selectedData)"
          >
            <v-icon small dark>mdi-check-bold</v-icon>

            <!-- {{ row.status == 1 ? "فایل تایید شده" : "تایید فایل" }} -->
            ثبت
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      answer: "",
    };
  },
  props: {
    selectedData: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style></style>
