<template>
  <v-container fluid class="px-0">
    <ticket-content-modal
      ref="ticketContentModalRef"
      :selectedData="selectedData"
      @GetList="GetList"
    />
    <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
    <MyDataTable
      title="تیکت های موجود"
      subtitle="مدیریت تیکت"
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      @onDeleteClick="deleteItem"
      @onEditClick="onEditClick"
      class="mt-n13 moholand-shadow"
    >
      <template #middleButtonTag="{ row }">
        <v-btn class="mx-1" dark small color="black" @click="onShowTicket(row)">
          <v-icon small dark>mdi-apps</v-icon>
          مشاهده
        </v-btn>
      </template>
      <template #contentTag="{ row }">
        <td>
          {{
            row.user && row.user.first_name && row.user.last_name
              ? row.user.first_name + " " + row.user.last_name
              : "-"
          }}
        </td>
        <td>{{ row.title }}</td>
        <td class="text-center">
          <v-chip
            v-if="row.status == 1"
            color="success"
            dark
            class="rounded"
            small
          >
            دارای پاسخ
          </v-chip>
          <v-chip v-else color="red" dark class="rounded" small>
            در حال بررسی
          </v-chip>
        </td>
        <td>{{ row.created_at | moment("hh:mm jYYYY/jMM/jDD") }}</td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import TicketsModal from "../../../../components/Panel/TicketsModal.vue";
import TicketContentModal from "../../../../components/Panel/TicketContentModal.vue";

export default {
  mounted() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    TicketsModal,
    TicketContentModal,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/tickets/index", {
        params: {
          page: this.$route.query?.page || 1,
          with: ["user", "parent"],
          conditions: {
            parent_id: null,
          },
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/tickets/edit/" + item.id,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.detailsModal.dialog = true;
    },
    onShowTicket(item) {
      this.selectedData = item;
      this.$refs.ticketContentModalRef.showModal();
    },
    deleteItem(item) {
      MyAxios.delete(`/tickets/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    onSubmitTicket(answer, selected) {
      console.log(answer, selected);
      if (answer.length <= 0) {
        this.$root.$emit("toast", {
          text: "پاسخ را به درستی وارد نمایید",
        });
        return;
      }
      this.$refs.detailsModal.loading = true;
      MyAxios.post("/tickets/create", {
        user_id: this.$store.state.userData.id,
        title: selected.title,
        parent_id: selected.id,
        desc: answer,
      })
        .then((response) => {
          this.$refs.detailsModal.loading = false;
          this.$refs.detailsModal.dialog = false;
          this.GetList();
        })
        .catch((error) => {
          this.$refs.detailsModal.loading = false;
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/tickets/index",
      pathParams: {
        with: ["user", "parent"],
      },
      headers: [
        { text: "کاربر", value: "user_id" },
        {
          text: "عنوان",
          align: "start",
          value: "title",
        },
        { text: "وضعیت", value: "child_id" },
        { text: "تاریخ ایجاد", value: "created_at" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
